import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About`}</h2>
    <p>{`I love making colorful websites !`}</p>
    <p>{`I've been programming on arduinos for fun since 2015, and I started making websites in React in 2021.`}</p>
    <p>{`Other than that I love bouldering, cooking tiramisu and going to music festivals.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      