import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Hi, I'm Anaïs ☀️`}</h1>
    <p>{`Welcome to my personal website !`}</p>
    <p>{`This is the place where you can find my latest projects and ways to contact me`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      