import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Projects`}</h2>
    <ProjectCard title="Radio Anaïs" link="https://twitter.com/AnaisMusicBot" bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" mdxType="ProjectCard">
  A Twitter bot that tweets the song I'm currently listening to on Spotify in
  real time.
    </ProjectCard>
    <ProjectCard title="Octoblog" link="https://blog.anaisderue.com/" bg="linear-gradient(236deg, rgba(70,136,218,1) 17%, rgba(158,42,255,1) 68%)" mdxType="ProjectCard">
  My personal blog, where I write about stuff that interests me.
    </ProjectCard>
    <ProjectCard title="Curvytron" link="https://curvytron.anaisderue.com/" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
  A 2 player Tron-like game, where you're always moving and you can only turn
  left or right.
    </ProjectCard>
    <ProjectCard title="Words" link="https://words.anaisderue.com/" bg="linear-gradient(to right, #009245 0%, #FCEE21 100%)" mdxType="ProjectCard">
  A Wordle-like game, open-source and playable both in french and english.
    </ProjectCard>
    <ProjectCard title="Fabzero" link="https://fablab-ulb.gitlab.io/enseignements/2021-2022/fabzero-experiments/students/anais.derue/
" bg="linear-gradient(43deg, rgba(251,225,63,1) 0%, rgba(251,215,64,1) 15%, rgba(252,99,70,1) 100%)" mdxType="ProjectCard">
  A website I created that documents what I learned in a course I took at the
  Free University of Brussels. In this course I learned different methods of
  rapid prototyping (3d printing, CAD, laser cutting, etc.).
    </ProjectCard>
    <ProjectCard title="2048" link="https://2048.anaisderue.com/" bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)" mdxType="ProjectCard">
  An open-source 2048 clone.
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      